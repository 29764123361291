import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const bucher: FC = () => {
  const titleImage = "_DSC2255.jpg"
  const image1 = "_DSC2252.jpg"
  const image2 = "_DSC2257.jpg"
  const image3 = "_DSC2260.jpg"
  const image4 = "_DSC2268.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Bucher/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Bucher 2017
          <h5 className="single-project-text">
            La casa Bucher nel nucleo di Ropiana a Mergoscia aveva un camino a
            legna in ghisa che non riscaldava in modo ottimale, una pigna poco
            performante ed inoltre i pavimenti in pietra non erano isolati.
            L’intervento è stato quello di rimuovere i pavimenti e gli impianti
            di riscaldamento esistenti, isolare ed impermeabilizzare alcune
            parti critiche. Abbiamo studiato un nuovo concetto di riscaldamento
            che prevede una stufa a legna che possa scaldare velocemente l’aria
            all’arrivo dei proprietari e una pigna a legna da circa 2 tonnellate
            che possa poi mantenere, tramite la sua massa, gli ambienti
            temperati anche durante le notti. La pigna è stata costruita nel
            luogo originario ovvero sotto le scale che portano ai piani
            superiori. Al primo piano è stato ammodernato un bagno munendolo di
            piatto doccia in pietra della Valle Maggia e rivestimenti in resina.
          </h5>
        </h3>
        <div>
          <StaticImage
            src={"../../assets/images/Bucher/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Bucher/" + image2}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Bucher/" + image3}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Bucher/" + image4}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default bucher
